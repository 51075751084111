export const environment = {
  production: false,
  mockapi: true,
  clientIdGoolge:
  '426633935974-63tc30cbqp0elpodm40c7i4ouf7b3h90.apps.googleusercontent.com', //WEB
    firebase: {
      apiKey: 'AIzaSyDq8MMgeoIRmk3HNimWL1ynnkIogovZxoA',
      authDomain: 'tianguis-app-71868.firebaseapp.com',
      projectId: 'tianguis-app-71868',
      storageBucket: 'tianguis-app-71868.appspot.com',
      messagingSenderId: '597171857974',
      appId: '1:597171857974:web:956a895f7c15e53c2cc697',
      measurementId: 'G-QBXGFK0355',
      vapidKey:'BK5kXvJN0w0crdMRqURs4qSJvFdMwx1w9_D1jiW0z_puDcO06l2DyJz3hzmfyCcDN8rW-skRDVzDtF53D4QzkTA'
    },
 //urlapi: 'http://34.16.48.193:3000/',
  //urlapiNoti: 'http://34.16.48.193:3001',
  //urlapiChat: 'http://34.16.48.193:3003',
  urlapi: 'https://api.tianguis.app:3000/',
  urlapiNoti: 'https://api.tianguis.app:3001',
  urlapiChat: 'https://api.tianguis.app:3003',
};
